import React from "react";
import { Link } from "gatsby";

import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";
import imgFS from "../../assets/image/NuvemFiscalRodape1.png";
import imgFT from "../../assets/image/NuvemFiscalRodape2.png";

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={`bg-dark-cloud pt-13 pt-lg-27 pb-7 dark-mode-texts position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-25">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
                <div className="brand-logo mb-5">
                  <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      src={imgLW}
                      alt=""
                      className="dark-version-logo mx-auto mx-0"
                    />
                  </Link>
                </div>
                <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">
                Tudo o que você precisa em serviços fiscais e automação comercial em uma única API.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 offset-xl-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Documentação
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="https://dev.nuvemfiscal.com.br/docs"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Introdução
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="https://dev.nuvemfiscal.com.br/docs/api"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Referência API
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Nuvem Fiscal
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="https://suporte.nuvemfiscal.com.br"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Suporte
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/planos"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Planos
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/termos-de-uso-politica-privacidade"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Termos de uso e política de privacidade
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-lg-6 col-md-5 col-xs-8">
                  <div className="mb-10 mb-lg-0 mr-xl-12">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Comunidade
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <a href="https://github.com/nuvem-fiscal" className="font-size-5 text-lily-white gr-hover-text-blue-3">
                          GitHub
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-10 border-top">
            <div className="col-lg-6 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-3 text-bali-gray">
                  © 2022 Nuvem Fiscal, Todos os direitos reservados.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="footer-right mt-5 mt-lg-0">
                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCx2AXrRCu7qNEjojhee19xQ"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/nuvem-fiscal/"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/nuvem-fiscal"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-github" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-yellow-shape-img">
          <img src={imgFS} alt="" width="250px"/>
        </div>
        <div className="footer-direito-img">
          <img src={imgFT} alt="" width="300px"/>
        </div>
      </div>
    </>
  );
};

export default Footer;
