export const menuItems = [
         {
           name: "produtos",
           label: "Serviços",
           items: [
             { name: "nfe", label: "NFe", href: "/#nfe"},
             { name: "nfse", label: "NFSe", href: "/#nfse"},
             { name: "nfce", label: "NFCe", href: "/#nfce" },             
             { name: "cte", label: "CTe", href: "/#cte" },
             { name: "mdfe", label: "MDFe", href: "/#mdfe" },
             { name: "nfcom", label: "NFCom", href: "/#nfcom" },
             { name: "cnpj", label: "CNPJ", href: "/#cnpj" },
             { name: "cep", label: "CEP", href: "/#cep" },
           ],
         },
         {
          name: "planos",
          label: "Planos"
        },
        //        name: "account",
        //        label: "Account",
        //        items: [
        //          { name: "signin", label: "Sign In" },
        //          { name: "signin-image", label: "Sign In Image" },
        //          { name: "signup", label: "Sign Up" },
        //          { name: "signup-image", label: "Sign Up Image" },
        //          { name: "reset-pass", label: "Reset Password" },
        //        ],
        //      },
        //    ],
        //  },
         {
          name: "https://dev.nuvemfiscal.com.br/docs",
          label: "Documentação",
          isExternal: true,
        },
        {
          name: "https://suporte.nuvemfiscal.com.br/",
          label: "Suporte",
          isExternal: true,
         },
     ];
